/* General Reset and Font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.auth-background {
    background-color: rgb(36, 48, 35);
    color: #ffffff;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
}

.logged-in-background {
    background-color: rgba(255, 255, 255, 1);
    color: black;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
}

.logo-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(36, 48, 35);
    width: 250px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1000;
    margin-top: 20px;
    margin-left: 20px;
}

.logo-container img {
    width: 200px;
    padding-top: 20px;
    align-content: center;
    justify-content: center;
}

.sidebar {
    width: 100px;
    background-color: transparent;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    margin-left: 40px;
}

.flex-wrapper {
    display: flex;
    align-items: center;
}

.merchant-id-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-size: 17px;
  color: rgb(0, 0, 0);
  overflow: hidden;
  text-overflow: ellipsis;
  position: fixed;
  z-index: 1000;
  font-weight: normal;
  margin-left: 700px;
  margin-top: 35px;
  white-space: nowrap;
}

.merchant-id-container .bold {
  font-weight: bold;
  white-space: nowrap;
}

.sidebar nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    z-index: 9999;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
}


.sidebar li {
    width: 120%;
    text-align: center;
    background-color: rgb(36, 48, 35);
    padding: 20px 0;
    border-radius: 0;
    border-left: 1px solid rgb(36, 48, 35);
    border-right: 1px solid rgb(36, 48, 35);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar li:first-child {
    border-radius: 10px 10px 0 0;
    padding-top: 30px;
}

.sidebar li:last-child {
    border-radius: 0 0 10px 10px;
    padding-bottom: 30px;
}

.sidebar li img {
    width: 35px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.sidebar li img:hover {
    transform: scale(1.1);
}

.sidebar li:hover::after,
.sidebar-footer .footer-item:hover::after {
    content: attr(data-label); /* Tooltip text */
    position: absolute;
    left: calc(100% + 10px);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 100;
    display: flex;
    align-items: center;
}

.sidebar li:hover::before,
.sidebar-footer .footer-item:hover::before {
    content: '';
    position: absolute;
    left: 105%;
    width: 0;
    height: 0;
    border-right: 5px solid black;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    z-index: 100;
}

/* Ensure the list item takes full width */
.sidebar ul li {
  width: 120%;
}

/* Ensure the link takes full width and height of the li element */
.full-clickable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

/* Make sure the image doesn't interfere with the click area */
.full-clickable img {
  pointer-events: none;
}

.sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
    border-radius: 15px;
}

.sidebar-footer .footer-item {
    width: 120px;
    height: 65px;
    margin-bottom: 20px;
    background-color: rgba(39, 245, 128, 0.851);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.sidebar-footer .footer-item:hover::after {
    left: calc(100% + 10px);
}

.sidebar-footer .footer-item:hover::before {
    left: 105%;
}

.sidebar-footer .footer-item img {
    width: 30px;
    transition: transform 0.3s ease;
}

.sidebar-footer .footer-item img:hover {
    transform: scale(1.1);
}

.sidebar-footer .logout-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.main-content {
    margin-left: 200px;
    padding: 20px;
    padding-top: 50px;
    background-color: #ffffff;
    height: 100vh;
    overflow-y: auto;
}


.home-page {
    top: 140px;
    left: 170px;
    padding: 20px;
    width: 85%;
    position: fixed;
  }
  
  .date-picker-container {
    margin-bottom: 20px;
    position: relative;
    overflow: visible;
    z-index: 10;
  }
  
  .date-picker-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    font-size: 16px;
    background-color: #f8f8f8;
    border: 1px solid #535353;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .date-picker-button img {
    width: 25px;
    margin-right: 15px;
    margin-left: -10px;
  }

  .calendar-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.react-calendar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 350px;
    background-color: #f8f8f8;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    z-index: 9999;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.react-calendar--open {
    opacity: 1;
    transform: translateY(0);
    z-index: 9999;
    position: absolute;
}

.react-calendar__navigation button {
    background: none;
    border: none;
    font-size: 20px;
    color: #333;
    padding: 10px;
}

.react-calendar__navigation button:hover {
    background-color: rgba(35, 48, 48, 1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
}

.react-calendar__navigation span {
    font-weight: 600;
    font-size: 16px;
}

.react-calendar__month-view__weekdays__weekday {
    color: #6b6b6b;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.react-calendar__tile {
    background: none;
    border: none;
    box-shadow: none;
    padding: 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 8px;
}

.react-calendar__tile--now {
    background-color: rgba(39, 245, 128, 0.272);
    color: rgb(26, 150, 80);
    font-weight: bold;
}

.react-calendar__tile--active {
    background-color: rgba(39, 245, 128);
    color: white;
    font-weight: bold;
}

.react-calendar__tile--range {
    background-color: rgba(39, 245, 128, 0.272);
    color: #77777765;
    border-radius: 0;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
    background-color: rgba(39, 245, 128);
    color: rgb(0, 0, 0);
    border-radius: 8px;
}

.react-calendar__tile--rangeStart {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.react-calendar__tile--rangeEnd {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.react-calendar__month-view__days__day--weekend {
    color: #000000;
}

.react-calendar__tile--disabled {
    color: #d1d5db;
    opacity: 0.6;
}

.react-calendar__tile:hover {
    background-color: rgba(35, 48, 48, 1);
    color: white;
    border-radius: 8px;
}

.react-calendar-animate {
    animation: calendar-slide-down 0.5s ease forwards;
}

@keyframes calendar-slide-down {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
  
  .stats-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-left: -179px;
  }
  
  .stat-box {
    background-color: #f8f9fa;
    padding: 10px;
    margin-right: -300px;
    border-radius: 15px;
    text-align: left;
    width: 17%;
    color: #828282;
    font-size: large;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .stat-box span {
    padding: 10px;
    text-align: left;
    margin-left: -10px;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
  
  .stat-box-a {
    background-color: rgba(39, 245, 128);
    padding: 10px;
    border-radius: 15px;
    text-align: left;
    width: 36%;
    color: #000000;
    font-size: large;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .stat-box-a span {
    padding: 10px;
    text-align: left;
    margin-left: -10px;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
  
  .transactions-table-container {
    margin-top: 20px;
    background-color: #f8f9fa;
    border: 1px solid #535353;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .transactions-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.transactions-table th,
.transactions-table td {
    padding: 12px;
    text-align: right;
    border: 1px solid #535353;
    width: 1%;
}

.transactions-table thead th {
    color: rgb(0, 0, 0);
}

.transactions-table tbody tr:nth-child(odd) {
    background-color: #f1f1f1;
}

.transactions-table tfoot th {
    background-color: #f8f9fa;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: large;
}

.transactions-table th:first-child,
.transactions-table td:first-child {
    border-left: none;
    border-right: none;
    text-align: left;
}

.transactions-table th:last-child,
.transactions-table td:last-child {
    border-right: none;
}

.transactions-table thead th {
    border: none;
    text-align: leftright;
}

.transactions-table tfoot th {
    border: none;
}

.transactions-container-m {
  top: 140px;
  left: 170px;
  padding: 20px;
  width: 85%;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.transactions-header-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.navigation-links .nav-link {
  margin-left: 20px;
  text-decoration: none;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  z-index: 10;
}

.navigation-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* Adjust as needed */
}

.filters-m {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.filters-m input,
.filters-m select,
.filters-m .date-picker-button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.clear-button {
  padding: 10px 20px;
  background-color: #f8f8f8;
  border: 1px solid #535353;
  border-radius: 5px;
  cursor: pointer;
}

.search-button {
  padding: 11px 20px;
  background-color: rgba(39, 245, 128, 0.851);
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.export-container-m {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: -60px;
}

.export-button {
  padding: 11px 20px;
  background-color: rgba(39, 245, 128, 0.851);
  color: black;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 9999;
}

.export-button:hover {
  transform: scale(1.1);
}

.transactions-list-table-m {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.transactions-list-table-m thead th {
  background-color: rgb(36, 48, 35);
  color: white;
  text-align: center;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.transactions-list-table-m thead th:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.transactions-list-table-m thead th:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.transactions-list-table-m tbody {
  display: block;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  border-radius: 10px;
}

.transactions-list-table-m tbody td {
  padding: 5px;
  text-align: left;
  background-color: #f8f9fa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  max-width: 200px;
}

.transactions-list-table-m tbody tr:nth-child(odd) {
  border-radius: 10px;
}

.transactions-list-table-m thead,
.transactions-list-table-m tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.transactions-list-table-m tbody::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.transactions-list-table-m tbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.transactions-list-table-m tbody::-webkit-scrollbar-track {
  background-color: #f8f9fa;
}

.transactions-list-table-m thead th,
.transactions-list-table-m tbody td {
  text-align: center;
  padding: 10px;
  box-sizing: border-box; /* Ensure padding is included in width calculations */
  overflow: hidden;
  text-overflow: ellipsis;
}

.transactions-list-table-m th,
.transactions-list-table-m td {
  padding: 10px;
  text-align: center;
}

.transactions-list-table-m th:nth-child(1),
.transactions-list-table-m td:nth-child(1) {
  width: 130px;
  text-align: center;
}

.transactions-list-table-m th:nth-child(2),
.transactions-list-table-m td:nth-child(2) {
  width: 150px;
}

.transactions-list-table-m th:nth-child(3),
.transactions-list-table-m td:nth-child(3) {
  width: 80px;
}

.transactions-list-table-m th:nth-child(4),
.transactions-list-table-m td:nth-child(4) {
  width: 80px;
}

.transactions-list-table-m th:nth-child(5),
.transactions-list-table-m td:nth-child(5) {
  width: 120px;
}

.transactions-list-table-m th:nth-child(6),
.transactions-list-table-m td:nth-child(6) {
  width: 80px;
}

.transactions-list-table-m th:nth-child(7),
.transactions-list-table-m td:nth-child(7) {
  width: 80px;
}

.transactions-list-table-m th:nth-child(8),
.transactions-list-table-m td:nth-child(8) {
  width: 100px;
}

.transactions-list-table-m th:nth-child(9),
.transactions-list-table-m td:nth-child(9) {
  width: 100px;
}

.transactions-list-table-m td img {
  width: 14px;
  margin-right: 9px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.transactions-list-table-m td img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.transactions-list-table-m th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.transactions-list-table-m tbody tr:nth-child(odd) {
  background-color: #f8f8f8; /* Light grey for odd rows */
}

.transactions-list-table-m tbody tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

/* Hover effect for rows */
.transactions-list-table-m tbody tr:hover {
  background-color: #f1f1f1;
}

/* Button styling inside the table */
.transactions-list-table-m button {
  padding: 5px 10px;
  border: none;
  background-color: rgba(39, 245, 128, 0.851);
  color: black;
  border-radius: 5px;
  cursor: pointer;
}

.transaction-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  border-radius: 8px;
  width: 500px;
}

.transaction-details-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.transaction-details-content p {
  margin-bottom: 10px;
  font-size: 14px;
}

.transaction-details-content p strong {
  font-weight: 600;
}

.close-modal-button {
  padding: 10px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}

.close-modal-button:hover {
  background-color: #c9302c;
}

.filters-m, .clear-button, .search-button {
  position: relative;
}

.filters-m input, .filters-m select, .clear-button, .search-button {
  pointer-events: auto;
}

/* Tooltip styling */
.transactions-list-table-m td[data-fulltext] {
  position: relative;
}

.transactions-list-table-m td[data-fulltext]:hover::after {
  content: attr(data-fulltext);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 9999;
  pointer-events: none;
}

.payout-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.wallets-container {
  top: 235px;
  left: 700px;
  padding: 20px;
  width: 85%;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.wallets-container div {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  width: 500px;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.wallets-container div h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallets-container div h3 img {
  margin-left: 10px;
  width: 30px;
}

.wallets-container div p {
  font-size: 16px;
  color: #333;
  word-wrap: break-word;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(36, 48, 35);
  background-color: #f9f9f9;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.wallets-container div p img {
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.wallets-container div p img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.eth-wallet {
  background-color: #ccc;
}

.sol-wallet {
  background-color: #ccc;
}

.trx-wallet {
  background-color: #ccc;
}

.support-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  margin-left: 500px;
  margin-top: 550px;
}

.support {
  width: 700px;
  height: 500px;
  padding-top: 170px;
  margin-left: 150px;
  margin-top: 10px;
  background-color: rgba(39, 245, 128, 0.851);
  border: 1px solid rgb(36, 48, 35);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
  max-width: 90vw; /* 90% of the viewport width */
  max-height: 90vh; /* 90% of the viewport height */
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.support h2 {
  color: #333;
  text-align: center; /* Ensure text is centered */
  margin-bottom: 30px;
}

.support p {
  font-size: 19px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.support p1 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #000;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(0, 0, 0); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .10em; /* Adjust as needed */
  animation: 
    typing 2s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 37% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(0, 0, 0); }
}

.gateway-manager {
  position: absolute;   /* Keep the container in a fixed place relative to its positioned ancestor */
  top: 180px;            /* Adjust top position according to the header */
  left: 350px;          /* Adjust left position to account for sidebar */
  width: calc(80% - 250px); /* Responsive width, accounting for the left margin */
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 590px;        /* Fixed height */
  overflow: auto;       /* Allow content to scroll if it overflows */
  z-index: 1;           /* Maintain its stacking order, but not above everything */
}

.gateway-links {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.gateway-links button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: rgba(39, 245, 128, 0.851);
  color: #000;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gateway-links button:hover {
  transform: scale(1.05);
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.form-group {
  display: flex;
  align-items: center;  /* Align items vertically centered */
  margin-bottom: 5px;  /* Space between form groups */
}


/* Label Styling */
.form-group label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  white-space: nowrap;  /* Prevent text wrapping */
}

/* Checkbox Styling */
.form-group input[type="checkbox"] {
  margin-left: 10px;
  margin-right: 5px;  /* Space between checkbox and label text */
  margin-top: 22px;
  vertical-align: middle;  /* Aligns checkbox with text */
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select {
  margin-top: 10px;
  margin-left: 20px;
  padding: 8px 12px; /* Padding inside the inputs */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  align-items: center;
  text-align: center;
  align-content: center;
  width: auto; /* Adjust width to fit content */
  box-sizing: border-box; /* Ensure padding does not affect width */
}

/* Disabled Input Styling */
.form-group input[type="number"]:disabled,
.form-group select:disabled {
  background-color: #e9e9e9;
  cursor: not-allowed;
}

form label input[type="text"],
form label input[type="number"],
form label select {
  width: 70%;
  padding: 10px;
  margin-top: 5px;
  margin-left: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

form label input[type="checkbox"] {
  margin-right: 5px;
}

form label input[type="number"]:disabled,
form label select:disabled {
  background-color: #e9e9e9;
  cursor: not-allowed;
}

form button[type="submit"] {
  padding: 10px 20px;
  background-color: rgba(39, 245, 128, 0.851);
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: transform 0.3s ease;
}

form button[type="submit"]:hover {
  transform: scale(1.02);
}

.my-gateways {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 4 items per row */
  gap: 10px; /* Space between items */
  justify-items: center;
}

.gateway-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center; /* Center the content within the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.gateway-item h3 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.gateway-icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.details-button {
  padding: 10px 20px;
  background-color: rgba(39, 245, 128, 0.851);
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.details-button:hover {
  transform: scale(1.05);
}

.custom-select-container {
  position: relative;
  width: 100%;
  max-width: 300px; /* Adjust based on your design */
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 10px;
}

.custom-select-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  align-content: center;
}

.custom-select-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.custom-select-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 150px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 1;
  max-height: 200px; /* Adjust as necessary */
}

.custom-select-option {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-select-option:hover {
  background-color: #f0f0f0;
}

.custom-select-option img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.popup-content p {
  margin-top: 5px;
}

.popup-content h3 {
  margin-bottom: 20px;
}